import dayjs from '@/logic/services/date/dateService';
import { getToday } from '../../helpers/dateUtils';
import { fetchWithRetry } from '../../helpers/fetchWithRetry';
import { getConfigurationArray, getConfigurationNumber, getPageLanguage, getConfigurationValue } from '../../helpers/utils';
import { httpGet } from '../../services/httpService';

export const EXTRA_SERVICES = Object.freeze({
  REFUND_PROTECT: 'RP',
  TRAVEL_INSURANCE: 'TI',
  SUPPORT_PLUS: 'SP',
  FLEXI: 'FLH'
});

export const EXTRA_SERVICE_PROVIDERS = Object.freeze({
  INTERAMERICAN: 'Interamerican',
  BOOKINGPROTECT: 'BookingProtect',
  FERRYHOPPER: 'Ferryhopper'
});

export const EXTRA_SERVICE_OPTIONS = Object.freeze({
  STANDARD: 'STANDARD',
  EXTENDED: 'EXTENDED'
});

const getExtraServiceDetails = (serviceType, serviceProviderName, withCredentials = false) => {
  let params = serviceProviderName ? `?serviceProvider=${serviceProviderName}` : '';
  return httpGet(`/${getPageLanguage()}/special-offers-api/extra-service/${serviceType}${params}`, {}, withCredentials);
};

export const getExtraServiceWithRetry = (serviceType, serviceProviderName, withCredentials = false) =>
  fetchWithRetry(() => {
    return getExtraServiceDetails(serviceType, serviceProviderName, withCredentials);
  });

export const isExtraServiceSelected = selection => selection === EXTRA_SERVICE_OPTIONS.EXTENDED;

export const tripsAllowRefundProtect = () => {
  return true;
};

export const tripsAllowTravelInsurance = (trips, passengerDetailsRequirements) => {
  const { birthday: isBirthDayRequired } = passengerDetailsRequirements;
  if (!isBirthDayRequired) return false;
  return trips.every(trip => {
    return trip.Details.DepCountry === 'GR' && trip.Details.ArrCountry === 'GR';
  });
};

export const tripsAllowExtraService = (trips, passengerDetailsRequirements, extraService) => {
  switch (extraService) {
    case EXTRA_SERVICES.REFUND_PROTECT:
      return tripsAllowRefundProtect();
    case EXTRA_SERVICES.TRAVEL_INSURANCE:
      return tripsAllowTravelInsurance(trips, passengerDetailsRequirements);
    case EXTRA_SERVICES.SUPPORT_PLUS:
      return tripsAllowSupportPlus(trips);
    case EXTRA_SERVICES.FLEXI:
      return tripsAllowFlexi(trips);
    default:
      return false;
  }
};

export const tripsAllowSupportPlus = trips => {
  return trips.every(trip => trip.tripAncillaries.supportPlus);
};

const isMinMaxWindowApplicable = trips => {
  // This try/catch is not needed, but it's here to avoid breaking the app because of a bug
  // that I had to correct in a hurry, and lack of QA resources at that moment. We can remove
  // it if we add unit tests
  try {
    const laterDate = trips.map(trip => trip.timings.DepartureDateTime).sort((dateA, dateB) => (dayjs(dateA).isAfter(dayjs(dateB)) ? -1 : 1))[0];

    const days = dayjs(laterDate).diff(getToday(), 'days');
    const isMaxWindowValid = days <= getConfigurationNumber('flexihopper_maximum_booking_window', 10);
    const isMinWindowValid = days >= getConfigurationNumber('flexihopper_minimum_booking_window', 2);

    return isMaxWindowValid && isMinWindowValid;
  } catch (e) {
    return false;
  }
};

export const tripsAllowFlexi = trips => {
  return trips.every(trip => trip.tripAncillaries.flexi) && isMinMaxWindowApplicable(trips);
};

/**
 * Returns true if an extra service is activated for the current locale, reading feature
 * flags from window object
 *
 * @param {String} extraService
 * @returns
 */
export const isExtraServiceActivated = extraService => {
  switch (extraService) {
    case EXTRA_SERVICES.REFUND_PROTECT:
      return getConfigurationArray('addon_refund_protect', []).includes(getPageLanguage());
    case EXTRA_SERVICES.TRAVEL_INSURANCE:
      return getConfigurationArray('addon_travel_insurance', []).includes(getPageLanguage());
    case EXTRA_SERVICES.SUPPORT_PLUS:
      return getConfigurationArray('addon_support_plus', []).includes(getPageLanguage());
    case EXTRA_SERVICES.FLEXI:
      return getConfigurationArray('addon_flexi', []).includes(getPageLanguage());
    default:
      return false;
  }
};

export const getDefaultServiceProvider = extraService => {
  switch (extraService) {
    case EXTRA_SERVICES.REFUND_PROTECT:
      return EXTRA_SERVICE_PROVIDERS.BOOKINGPROTECT;
    case EXTRA_SERVICES.TRAVEL_INSURANCE:
      return EXTRA_SERVICE_PROVIDERS.INTERAMERICAN;
    case EXTRA_SERVICES.SUPPORT_PLUS:
      return EXTRA_SERVICE_PROVIDERS.FERRYHOPPER;
    case EXTRA_SERVICES.FLEXI:
      return EXTRA_SERVICE_PROVIDERS.FERRYHOPPER;
    default:
      return undefined;
  }
};

const ancillariesAlt = {
  SP: 'SupportPlusAltComponent',
  RP: 'RefundOptionsAltComponent',
  TI: 'TravelInsuranceOptionsAltComponent'
};

const ancillaries = {
  SP: 'SupportPlusComponent',
  RP: 'RefundOptionsComponent',
  TI: 'TravelInsuranceOptionsComponent',
  FLH: 'FlexiComponent'
};

const isSingleCardAncillaries = () => {
  return getConfigurationValue('ancillaries_cards_design', 'multiple') === 'single';
};

export const getSortedAncillaries = () => {
  const ancillariesList = getConfigurationArray('ancillaries_sorting', []);
  const ancillariesComponents = isSingleCardAncillaries() ? ancillariesAlt : ancillaries;

  return ancillariesList.map(extraServiceType => ({
    extraServiceType,
    component: ancillariesComponents[extraServiceType]
  }));
};
